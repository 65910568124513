<template>
  <div>
    <FilterOverview title="Releaseprozesse" :showFilterInitially="false" @reload="onReload">
      <template #title-right>
        <b-button
          class="btn-xs btn p-2 ml-2 justify-content-center align-item-center d-flex"
          v-b-tooltip.hover
          :title="releaseprozessTitleHoverText"
        >
          <i class="fas fa-info m-0 p-0"></i>
        </b-button>
      </template>
      <template #toolbar-left>
        <b-form-checkbox
          v-model="tenDaysBeforeReleaseFilter"
          class="mr-5"
          @change="gridApi.onFilterChanged()"
          switch
        >
          Release nächste 10 Tage
        </b-form-checkbox>
        <b-button size="sm" class="ml-2" variant="danger" v-if="isFilterSupplied" @click="resetAllFilters">
          Alle Filter zurücksetzen
        </b-button>
      </template>
      <template #table="{ tableHeight }">
        <ag-grid-vue
          class="ag-theme-alpine m-0 p-0"
          :columnDefs="columnDefs"
          :style="{ height: tableHeight + 80 + 'px' }"
          :rowModelType="rowModelType"
          :defaultColDef="defaultColDef"
          :tooltipShowDelay="0"
          :getRowHeight="getRowHeight"
          suppressCellFocus
          :statusBar="statusBar"
          @rowClicked="onRowClick"
          @grid-ready="onGridReady"
        ></ag-grid-vue>
      </template>
    </FilterOverview>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import CustomHeader from '@/components/flugverfuegbarkeit/custom-header.vue';
import FilterOverview from '@/components/common/filter-overview.vue';
import { tableConfigReleaseprozessDashboard } from '@/core/produkte/reisetermine/releaseprozess-reisetermine.config.js';
import * as odataService from '@/core/common/services/odata.service';
import { add } from 'date-fns';
import CellRendererPax from '@/components/produkte/reisetermine/cell-renderer-pax.vue';
import CellRendererIcon from '@/components/produkte/reisetermine/cell-renderer-icon.vue';
import CellRendererAufgaben from '@/components/produkte/reisetermine/cell-renderer-aufgaben.vue';
import AgDateRangePicker from '@/components/flugverfuegbarkeit/ag-date-range-picker.vue';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'DomReleaseprozessDashboard',
  components: {
    FilterOverview,
    AgGridVue,
    agColumnHeader: CustomHeader,
    CellRendererPax,
    CellRendererIcon,
    CellRendererAufgaben,
    AgDateRangePicker,
    StatusBarComponent,
  },
  data() {
    return {
      statusBar: {
        statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
      },
      releaseprozessTitleHoverText:
        'Die Releaseprozess Übersicht spiegelt den Fortschritt des Releaseprozesse eines Reisetermins wider und dient dem DOM als Qualitätskontrolle. Es werden alle Reisetermine mit dem Status “Aufgelegt” und einem Startdatum ab heute angezeigt.',
      count: null,
      isFilterSupplied: false,
      columnDefs: tableConfigReleaseprozessDashboard,
      rowModelType: 'serverSide',
      tenDaysBeforeReleaseFilter: false,
      expand: {
        reise: {},
        pax: {},
        vorgangReleaseprozess: {},
        aufgaben: { orderBy: ['faelligkeitsdatum'], filter: { status: { ne: 'Erledigt' } } },
        metadata: { select: ['minPax', 'maxPax', 'kalkPax', 'erwartetePax', 'durchfuehrbareMinPax'] },
      },
      defaultColDef: {
        resizable: false,
        sortable: false,
        supressMenu: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        filterParams: {
          defaultToNothingSelected: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters(['getKategorienReleaseprozess', 'getKategorienDestinationYield']),
    releaseprozessAufgabenKategorienLabels() {
      return this.getKategorienReleaseprozess?.map(kategorie => kategorie.aufgabenKategorie);
    },
  },
  methods: {
    onReload() {
      this.gridApi.onFilterChanged();
    },
    updateStatusBar(displayedRowsCount) {
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setRowCount(displayedRowsCount);
      statusBarComponent?.setOdataCount(this.count);
    },
    checkIfFilterSupplied() {
      this.isFilterSupplied =
        this.gridApi &&
        (Object.keys(this.gridApi?.getFilterModel()).length > 0 || this.tenDaysBeforeReleaseFilter);
    },
    resetAllFilters() {
      this.gridApi.setFilterModel(null);
      let filterDate1 = this.gridApi.getFilterInstance('startdatum');
      let filterDate2 = this.gridApi.getFilterInstance('versandReiseunterlagen');
      let filterDate3 = this.gridApi.getFilterInstance('releasedatum');
      filterDate1.onDateRangeFilterChanged(null);
      filterDate2.onDateRangeFilterChanged(null);
      filterDate3.onDateRangeFilterChanged(null);
      this.tenDaysBeforeReleaseFilter = false;
    },
    getRowHeight(params) {
      return params.data
        ? Math.max(
            1,
            params.data.aufgaben.filter(
              aufgabe =>
                this.releaseprozessAufgabenKategorienLabels?.includes(aufgabe.kategorie) ||
                this.getKategorienDestinationYield.includes(aufgabe.kategorie)
            ).length
          ) * 45
        : 45;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const updateData = () => {
        const server = this.server();
        const datasource = this.createDatasource(server);
        params.api.setServerSideDatasource(datasource);
      };

      updateData();
    },
    createDatasource(server) {
      return {
        getRows: async params => {
          this.checkIfFilterSupplied();

          const response = await server.getData(params.request);
          if (response.success) {
            params.success({
              rowData: response.rows,
            });
            this.updateStatusBar(this.gridApi?.getDisplayedRowCount());
          } else {
            params.fail();
          }
        },
      };
    },
    server() {
      return {
        getData: async request => {
          console.log('[Datasource] - rows requested by grid: ', request);
          const response = await odataService.getReisetermin({
            filter: this.getOdataFilterFromAgGridRequest(request),
            top: request.endRow - request.startRow,
            skip: request.startRow,
            count: true,
            expand: this.expand,
            ...(request.sortModel.length > 0
              ? {
                  orderBy: request.sortModel.map(s => s.colId.replaceAll('.', '/') + ' ' + s.sort),
                }
              : { orderBy: 'startdatum asc' }),
          });
          this.count = response.count;
          return {
            success: true,
            rows: response.data,
          };
        },
      };
    },
    getOdataFilterFromAgGridRequest(request) {
      const filterKeys = Object.keys(request.filterModel);
      let filters = [];

      if (this.tenDaysBeforeReleaseFilter) {
        filters = [
          {
            releasedatum: {
              le: add(new Date(), { days: 10 }),
              ge: new Date(),
            },
            abgesagt: { eq: false },
            aufgaben: {
              any: { status: { eq: 'Erledigt' }, kategorie: { eq: 'TempPruefungDurchfuehrung' } },
            },
          },
        ];
      }
      const allFilterKeys = filterKeys.map(key => {
        const formattedKey = key.replaceAll('.', '/');
        if (request.filterModel[key].filterType === 'text') {
          return { [formattedKey]: { startswith: request.filterModel[key].filter } };
        } else if (request.filterModel[key].filterType === 'set') {
          if (
            formattedKey === 'vorgangReleaseprozess/teilnehmerErledigt' ||
            formattedKey === 'vorgangReleaseprozess/reiseleistungListenversandErledigt'
          ) {
            const formatterReleaseprozessAmpel = {
              Grün: ['GeprueftVorhandenKorrekt', 'GeprueftNichtNotwendig'],
              Gelb: ['InArbeit', 'GeprueftNichtKorrektInArbeit'],
              Rot: ['NichtGeprueft'],
            };
            return {
              [formattedKey]: {
                in: request.filterModel[key].values.map(value => formatterReleaseprozessAmpel[value]).flat(),
              },
            };
          } else if (
            formattedKey === 'vorgangReleaseprozess/transferplanungErledigt' ||
            formattedKey === 'reiseunterlagenGeprueft' ||
            formattedKey === 'transferplanungGeprueft' ||
            formattedKey === 'garantiert'
          ) {
            const formatterReleaseprozessAmpel = {
              Grün: true,
              Rot: false,
            };
            return {
              [formattedKey]: {
                in: request.filterModel[key].values.map(value => formatterReleaseprozessAmpel[value]).flat(),
              },
            };
          } else if (formattedKey === 'vorgangReleaseprozess/fluegePassend') {
            const formatterReleaseprozessFluegeAmpel = {
              Grün: {
                and: {
                  'vorgangReleaseprozess/fluegePassend': true,
                  'vorgangReleaseprozess/flugticketsAusgestellt': true,
                },
              },
              Gelb: {
                or: [
                  {
                    and: {
                      'vorgangReleaseprozess/fluegePassend': true,
                      'vorgangReleaseprozess/flugticketsAusgestellt': false,
                    },
                  },
                  {
                    and: {
                      'vorgangReleaseprozess/fluegePassend': false,
                      'vorgangReleaseprozess/flugticketsAusgestellt': true,
                    },
                  },
                ],
              },
              Rot: {
                and: {
                  'vorgangReleaseprozess/fluegePassend': false,
                  'vorgangReleaseprozess/flugticketsAusgestellt': false,
                },
              },
            };
            if (request.filterModel[key].values.length === 1) {
              return {
                [formattedKey]: request.filterModel[key].values
                  .map(value => formatterReleaseprozessFluegeAmpel[value])
                  .flat(),
              };
            } else {
              return {
                or: request.filterModel[key].values.map(value => formatterReleaseprozessFluegeAmpel[value]),
              };
            }
          }
          return { [formattedKey]: { in: request.filterModel[key].values } };
        } else if (request.filterModel[key].filterType === 'date') {
          const dateFrom = new Date(request.filterModel[key].dateFrom.substring(0, 10));
          const dateTo = add(new Date(request.filterModel[key].dateTo), { hours: 23, minutes: 59 });
          if (formattedKey === 'versandReiseunterlagen') {
            return {
              unterlagenversandDatum: {
                ge: dateFrom,
                le: dateTo,
              },
            };
          } else {
            return {
              [formattedKey]: {
                ge: dateFrom,
                le: dateTo,
              },
            };
          }
        } else if (request.filterModel[key].filterType === 'boolean') {
          return { [formattedKey]: { eq: true } };
        }
      });

      filters = [...filters, { state: 'Aufgelegt', isDeleted: false }, ...allFilterKeys];
      return filters;
    },
    onRowClick($event) {
      const { data, rowIndex } = $event;
      this.selectedRowID = rowIndex;
      const routeData = this.$router.resolve({ path: '/reisetermine/' + data.id + '/releasepruefung' });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';
:deep(.ag-theme-alpine) {
  --ag-font-family: 'Poppins';
  --ag-font-size: 13px;
}
:deep(.ag-theme-alpine .ag-header-cell) {
  font-weight: 500;
  font-size: 14px;
}
:deep(.ag-theme-alpine .ag-header-group-cell) {
  font-weight: 500;
  font-size: 14px;
}
</style>
