import { formatDateWithoutHours } from '@/core/common/helpers/utils';
import { parseISO, sub } from 'date-fns';
import store from '@/core/common/services/vuex-store.js';

export const tableConfigReleaseprozessDashboard = [
  {
    field: 'reise.reisekuerzel',
    headerName: 'Reisekürzel',
    valueGetter: params => params.data.reiseterminkuerzel.substring(0, 6),
    minWidth: 170,
    width: 170,
    sortable: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: params => params.success(store.getters.getReisekuerzelOptions.map(reise => reise.reisekuerzel)),
      debounceMs: 1000,
      refreshOnLoad: true,
    },
    floatingFilter: true,
  },
  {
    field: 'startdatum',
    headerName: 'Startdatum',
    valueFormatter: params => formatDateWithoutHours(params.value),
    minWidth: 175,
    width: 175,
    sortable: true,
    sort: 'asc',
    filter: 'AgDateRangePicker',
    floatingFilter: true,
    floatingFilterComponent: 'AgDateRangePicker',
    floatingFilterComponentParams: {
      isChild: true,
    },
  },
  {
    field: 'releasedatum',
    headerName: 'Releasedatum',
    valueFormatter: params => formatDateWithoutHours(params.value),
    minWidth: 175,
    width: 175,
    sortable: true,
    filter: 'AgDateRangePicker',
    floatingFilter: true,
    floatingFilterComponent: 'AgDateRangePicker',
    floatingFilterComponentParams: {
      isChild: true,
    },
  },
  {
    field: 'versandReiseunterlagen',
    headerName: 'Versand',
    headerTooltip: 'Versand Reiseunterlagen',
    valueFormatter: params => formatDateWithoutHours(parseISO(params.data.unterlagenversandDatum)),
    minWidth: 175,
    width: 175,
    filter: 'AgDateRangePicker',
    floatingFilter: true,
    floatingFilterComponent: 'AgDateRangePicker',
    floatingFilterComponentParams: {
      isChild: true,
    },
  },
  {
    field: 'vorgangReleaseprozess.teilnehmerErledigt',
    headerName: 'TD',
    headerTooltip: 'Teilnehmerdaten',
    cellRenderer: 'CellRendererIcon',
    filter: 'agSetColumnFilter',
    floatingFilter: true,
    filterParams: {
      values: ['Grün', 'Gelb', 'Rot'],
      comparator: (a, b) => {
        const comparatorRanks = {
          Grün: 2,
          Gelb: 1,
          Rot: 0,
        };
        return comparatorRanks[a] - comparatorRanks[b];
      },
      cellRenderer: params => {
        if (params.value === 'Grün') {
          return '<div><i class="fas fa-user-check fa-lg text-success"></i> Geprüft</div>';
        } else if (params.value === 'Gelb') {
          return '<div><i class="fas fa-user-check fa-lg text-warning"></i> In Arbeit</div>';
        } else if (params.value === 'Rot') {
          return '<div><i class="fas fa-user-check fa-lg text-danger"></i> Nicht geprüft</div>';
        } else return '(Select All)';
      },
    },
    cellRendererParams: {
      icon: 'fas fa-user-check fa-lg',
      extra: 'passdatenStar',
      attribute: 'teilnehmerErledigt',
    },
    minWidth: 70,
    width: 70,
  },
  {
    field: 'vorgangReleaseprozess.reiseleistungListenversandErledigt',
    headerName: 'GL',
    headerTooltip: 'Gebuchte Leistungen',
    cellRenderer: 'CellRendererIcon',
    filter: 'agSetColumnFilter',
    floatingFilter: true,
    filterParams: {
      values: ['Grün', 'Gelb', 'Rot'],
      comparator: (a, b) => {
        const comparatorRanks = {
          Grün: 2,
          Gelb: 1,
          Rot: 0,
        };
        return comparatorRanks[a] - comparatorRanks[b];
      },
      cellRenderer: params => {
        if (params.value === 'Grün') {
          return '<div><i class="fas fa-suitcase fa-lg fa-lg text-success"></i> Geprüft</div>';
        } else if (params.value === 'Gelb') {
          return '<div><i class="fas fa-suitcase fa-lg fa-lg text-warning"></i> In Arbeit</div>';
        } else if (params.value === 'Rot') {
          return '<div><i class="fas fa-suitcase fa-lg fa-lg text-danger"></i> Nicht geprüft</div>';
        } else return '(Select All)';
      },
    },
    cellRendererParams: { icon: 'fas fa-suitcase fa-lg', attribute: 'reiseleistungListenversandErledigt' },
    minWidth: 70,
    width: 70,
  },
  {
    field: 'vorgangReleaseprozess.fluegePassend',
    headerName: 'F',
    cellRenderer: 'CellRendererIcon',
    filter: 'agSetColumnFilter',
    floatingFilter: true,
    valueGetter: params => {
      if (
        params.data.vorgangReleaseprozess.fluegePassend === null ||
        params.data.vorgangReleaseprozess.flugticketsAusgestellt === null
      ) {
        return null;
      } else
        return params.data.vorgangReleaseprozess.fluegePassend &&
          params.data.vorgangReleaseprozess.flugticketsAusgestellt
          ? 'text-success'
          : params.data.vorgangReleaseprozess.fluegePassend ||
            params.data.vorgangReleaseprozess.flugticketsAusgestellt
          ? 'text-warning'
          : 'text-danger';
    },
    filterParams: {
      values: ['Grün', 'Gelb', 'Rot'],
      comparator: (a, b) => {
        const comparatorRanks = {
          Grün: 2,
          Gelb: 1,
          Rot: 0,
        };
        return comparatorRanks[a] - comparatorRanks[b];
      },
      cellRenderer: params => {
        if (params.value === 'Grün') {
          return '<div><i class="fas fa-plane fa-lg fa-lg text-success"></i> Geprüft</div>';
        } else if (params.value === 'Gelb') {
          return '<div><i class="fas fa-plane fa-lg fa-lg text-warning"></i> In Arbeit</div>';
        } else if (params.value === 'Rot') {
          return '<div><i class="fas fa-plane fa-lg fa-lg text-danger"></i> Nicht geprüft</div>';
        } else return '(Select All)';
      },
    },
    cellRendererParams: { icon: 'fas fa-plane fa-lg', attribute: 'fluegePassend' },
    minWidth: 70,
    width: 70,
  },
  {
    field: 'vorgangReleaseprozess.transferplanungErledigt',
    headerName: 'TP',
    headerTooltip: 'Transferplanung auf Vorgangsebene',
    cellRenderer: 'CellRendererIcon',
    filter: 'agSetColumnFilter',
    floatingFilter: true,
    filterParams: {
      values: ['Grün', 'Rot'],
      comparator: (a, b) => {
        const comparatorRanks = {
          Grün: 1,
          Rot: 0,
        };
        return comparatorRanks[a] - comparatorRanks[b];
      },
      cellRenderer: params => {
        if (params.value === 'Grün') {
          return '<div><i class="fas fa-car fa-lg fa-lg text-success"></i> Geprüft</div>';
        } else if (params.value === 'Rot') {
          return '<div><i class="fas fa-car fa-lg fa-lg text-danger"></i> Nicht Geprüft</div>';
        } else return '(Select All)';
      },
    },
    cellRendererParams: { icon: 'fas fa-car fa-lg', attribute: 'transferplanungErledigt' },
    minWidth: 70,
    width: 70,
  },

  {
    field: 'reiseunterlagenGeprueft',
    headerName: 'RTE',
    headerTooltip: 'Reiseterminebene',
    cellRenderer: 'CellRendererIcon',
    filter: 'agSetColumnFilter',
    floatingFilter: true,
    filterParams: {
      values: ['Grün', 'Rot'],
      comparator: (a, b) => {
        const comparatorRanks = {
          Grün: 1,
          Rot: 0,
        };
        return comparatorRanks[a] - comparatorRanks[b];
      },
      cellRenderer: params => {
        if (params.value === 'Grün') {
          return '<div><i class="fas fa-list-alt fa-lg fa-lg text-success"></i> Geprüft</div>';
        } else if (params.value === 'Rot') {
          return '<div><i class="fas fa-list-alt fa-lg fa-lg text-danger"></i> Nicht Geprüft</div>';
        } else return '(Select All)';
      },
    },
    cellRendererParams: { icon: 'far fa-list-alt fa-lg', attribute: 'reiseunterlagenGeprueft' },
    minWidth: 70,
    width: 70,
  },
  {
    field: 'transferplanungGeprueft',
    headerName: 'FTP',
    headerTooltip: 'Finale Transferplanung',
    cellRenderer: 'CellRendererIcon',
    filter: 'agSetColumnFilter',
    floatingFilter: true,
    filterParams: {
      values: ['Grün', 'Rot'],
      comparator: (a, b) => {
        const comparatorRanks = {
          Grün: 1,
          Rot: 0,
        };
        return comparatorRanks[a] - comparatorRanks[b];
      },
      cellRenderer: params => {
        if (params.value === 'Grün') {
          return '<div><i class="fas fa-bus fa-lg fa-lg text-success"></i> Geprüft</div>';
        } else if (params.value === 'Rot') {
          return '<div><i class="fas fa-bus fa-lg fa-lg text-danger"></i> Nicht Geprüft</div>';
        } else return '(Select All)';
      },
    },
    cellRendererParams: { icon: 'fas fa-bus fa-lg', attribute: 'transferplanungGeprueft' },
    minWidth: 70,
    width: 70,
  },
  {
    field: 'garantiert',
    headerName: 'Garantiert',
    cellRenderer: 'CellRendererIcon',
    filter: 'agSetColumnFilter',
    floatingFilter: true,
    filterParams: {
      values: ['Grün', 'Rot'],
      comparator: (a, b) => {
        const comparatorRanks = {
          Grün: 1,
          Rot: 0,
        };
        return comparatorRanks[a] - comparatorRanks[b];
      },
      cellRenderer: params => {
        if (params.value === 'Grün') {
          return '<div><i class="fas fa-check text-success fa-lg"></i> Geprüft</div>';
        } else if (params.value === 'Rot') {
          return '<div><i class="ml-1 fas fa-times text-danger fa-lg"></i> Nicht Geprüft</div>';
        } else return '(Select All)';
      },
    },
    cellRendererParams: { icon: 'fas fa-check fa-lg', attribute: 'garantiertErledigt' },

    minWidth: 130,
    width: 130,
  },
  {
    field: 'pax',
    headerName: 'PAX',
    cellRenderer: 'CellRendererPax',
    minWidth: 200,
    width: 200,
    filter: false,
    suppressRowTransform: true,
    cellStyle: {
      'line-height': 'normal',
      display: 'flex',
      'justify-items': 'center',
      overflow: 'visible',
    },
    cellRendererParams: params => ({
      paxObject: params.data.pax,
      minPax: params.data.metadata.minPax,
      maxPax: params.data.metadata.maxPax,
      kalkPax: params.data.metadata.kalkPax,
      erwartetePax: params.data.metadata.erwartetePax,
      durchfuehrbareMinPax: params.data.metadata.durchfuehrbareMinPax,
      id: params.data.id,
    }),
  },
  {
    field: 'aufgaben',
    headerName: 'Aufgaben',
    cellRenderer: 'CellRendererAufgaben',
    cellRendererParams: { filter: 'releaseprozess', ansicht: 'releaseprozess-overview' },
    minWidth: 500,
    width: 500,
    cellStyle: {
      'line-height': 'normal',
      display: 'flex',
      'align-items': 'center',
    },
  },
];
